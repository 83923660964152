import { environment } from 'src/environments/environment';

// import firebase from 'firebase/app';
import { Observable, of } from 'rxjs';
import { take, takeUntil, switchMap, map, tap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { User } from 'src/app/shared/interfaces/auth/user';
import { GoogleAuthProvider } from 'firebase/auth';

@Injectable({ providedIn: 'root' })
export class AuthService {

  public uid: Observable<string | undefined>;
  public loggedUser: Observable<User | null>;

  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFireDatabase,
  ) {
    this.uid = this.afAuth.user
      .pipe(map((user) => user?.uid));

    this.loggedUser = this.afAuth.user.pipe(
      switchMap((auth) => this.db.object<User>('users/' + auth?.uid).valueChanges())
    );
  }

  register(email: string, password: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  getAuth() {
    return this.afAuth.authState.pipe(map(auth => auth));
  }

  authenticateWithGoogle() {
    return this.afAuth.signInWithPopup(new GoogleAuthProvider())
  }

  loginWithEmail(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  logWithGoogle() {
  }

  public async signOut() {
    return this.afAuth.signOut();
  }

  public async sendResetPasswordMail(email: string) {
    return await this.afAuth.sendPasswordResetEmail(email)
  }

  /*
    public updatePassword(password: string) {
      return this.afAuth.currentUser
        .updatePassword(password)
        .then(() => {
          this.messageService.info('Password has been updated!');
        })
        .catch(function(error) {
          throw error;
        });
    }

    public updateEmail(email: string) {
      return this.afAuth.currentUser
        .updateEmail(email)
        .then(() => {
          this.updateExistingUser({ email: email });
          this.messageService.info('User email have been updated!');
        })
        .catch(function(error) {
          throw error;
        });
    }

     // public createUser(email: string, password: string) {
  //   let config = environment.secondaryAppConfig;
  //   let secondaryApp = firebase.initializeApp(config, "Secondary");
  //   return secondaryApp.auth().createUserWithEmailAndPassword(email, password)
  //     .then((user) => {
  //       setTimeout(() => {
  //         secondaryApp.auth().signOut();
  //       }, 10000);
  //       return user.user.uid;
  //     }, (error) => error);
  // }

  
  */

}
