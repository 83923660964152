import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})

export class NavService {

  public screenWidth: any
  public collapseSidebar: boolean = true;
  public fullScreen = false;

  constructor() {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true
    }
  }

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.screenWidth = window.innerWidth;
  }

  menuItems: Menu[] = [
    // { path: "/", icon: "home", title: "Dashboard", type: "link" },
    {
      title: "Account",
      icon: "home",
      type: "link",
      path: "/account"
      // type: "sub",
      // active: true,
      // children: [
      //   { path: "/account", title: "Details", type: "link" },
      //   { path: "/account/statement", title: "Statement", type: "link" },
      // ],
    },
  ]
  // Array
  items = new BehaviorSubject<Menu[]>(this.menuItems);

}
