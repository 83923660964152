export const environment = {
  production: true,
  // smtp2go_apikey:'api-3899D92AADF211EC9593F23C91C88F4E',//remon
  smtp2go_apikey: 'api-2B23DE9889E511EC91B8F23C91BBF4A0',//old
  // smtp2go_apikey:'api-992D616EF6E911EB9297F23C91C88F4E',//old
  firebase: {
    apiKey: "AIzaSyAisJEhlMG2cwtjC44NWZ3z8rxMEim9uTc",
    authDomain: "bankelisteghfar.firebaseapp.com",
    databaseURL: "https://bankelisteghfar-default-rtdb.firebaseio.com",
    projectId: "bankelisteghfar",
    storageBucket: "bankelisteghfar.appspot.com",
    messagingSenderId: "150120315458",
    appId: "1:150120315458:web:dc9dd902df3829fa4c8bdb",
    measurementId: "G-GRLM5BDE1M"
  },
  verificationCode: '77510A74B072E7002B1B77510A74B072'
};
