import { Injectable } from '@angular/core';
import { Language } from './languages';

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  constructor() { }

  get storedLanguage() {
    return localStorage.getItem('i18n') || Language.English;
  }

  storeCurrentLanguage(lang: Language) {
    localStorage.setItem('i18n', lang);
  }
}
