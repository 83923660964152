/* eslint-disable func-names */
import { AbstractControl } from '@angular/forms';

/**
 * Extends FormControl with custom methods. We need to import this class in app-component for it to available throughout the project.
 * Usage form.control.xyz.isRequiredField().
 *
 * Reference: https://dev.to/digitalcrafting/a-case-for-extending-angular-forms-113l .
 */

declare module '@angular/forms' {
  interface AbstractControl {
    isRequiredField(): boolean;
    showError(): boolean;
  }
}
/**
 * Checks if the control has a required validator.
 */
AbstractControl.prototype.isRequiredField = function () {
  if (this.validator) {
    const validator = this.validator({} as AbstractControl);
    if (validator && validator['required']) {
      return true;
    }
  }
  return false;
};

/**
 * Its being used for error display. It takes into account the touch and dirty properties.
 */
AbstractControl.prototype.showError = function () {
  return this.invalid && (this.dirty || this.touched);
};

