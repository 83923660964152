
import { Injectable, inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';


export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {

  const router: Router = inject(Router);
  const authService: AuthService = inject(AuthService);

  return authService.uid.pipe(
    take(1),
    tap((user) => {
      if (!user) {
        router.navigate(['/auth/login']);
      }
    }),
    map((user) => {
      if (!user) return false;

      return true;
    })
  );



  // if (tokenStorage.isTokenExpired()) {
  //   return router.navigate(['forbidden']);    
  // }
  // else {
  //   const roles = route.data['permittedRoles'] as Array<string>;
  //   const userRole = tokenStorage.getUserToken().role;

  //   if (roles && !roles.includes(userRole)) {
  //     return router.navigate(['login']);
  //   }
  //   else
  //     return true;
  // }

}

// @Injectable({ providedIn: 'root' })
// export class AuthGuard implements CanActivate {
//   constructor(
//     private authService: AuthService,
//     private router: Router,
//   ) {  }

//   public canActivate(
//     next: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
//   ): Observable<boolean> | Promise<boolean> | boolean {


//     return this.authService.user.pipe(
//       take(1),
//       tap((user) => {
//         // const permissionKey = this.routePermisions.find(x => x.routerLink == path)?.permission_key || 'thereispermission';
//         // const userPermissions = this.roles.find(x => x.id == user?.role_id)?.permissions;
//         if (!user) {
//           this.router.navigate(['/login']);
//         }
//         else {
//           this.router.navigate(['/error/error403']);
//         }
//       }),
//       // map((user) => {
//       //   const permissionKey = this.routePermisions.find(x => x.routerLink == path)?.permission_key;
//       //   const userPermissions = this.roles.find(x => x.id == user?.role_id)?.permissions;
//       //   if (!user) {
//       //     return false
//       //   }
//       //   else if (userPermissions && permissionKey && !userPermissions?.includes(permissionKey) && user.userRoles != UserRolesKey.super_admin) {
//       //     return false
//       //   }
//       //   return true
//       // })
//     );
//   }
// }
