import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { ContentLayoutComponent } from "./shared/components/layout/content-layout/content-layout.component";
import { FullLayoutComponent } from "./shared/components/layout/full-layout/full-layout.component";
import { AuthGuard } from "./services/authentication/auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "account",
    pathMatch: "full",
  },
  {
    path: "",
    component: ContentLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () => import("./secure/secure.module").then((m) => m.SecureModule),
      }
    ]
  },
  {
    path: "",
    component: FullLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
      },
      {
        path: '',
        loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
      },
    ],
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    anchorScrolling: "enabled",
    scrollPositionRestoration: "enabled",
  }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
