import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getDate',
})
export class GetDatePipe implements PipeTransform {

  public pipe = new DatePipe("en-US");
  transform(value: number, ...args: string[]): string {
    if (isNaN(value)) return '';
    if (value == 0) return '';

    const defaultFormate = 'dd MMM yyyy hh:mm:ss a';
    const formate = args?.length > 0 ? args[0] : defaultFormate;
    const date = new Date(value);
    if (!date) return '';
    return this.pipe.transform(date, formate) || '';
  }

}
