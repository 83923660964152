<!-- Page Header Start-->
<div class="page-main-header" [ngClass]="{ open: navServices.collapseSidebar }" id="page-main-header">
  <div class="main-header-right row">
    <div class="main-header-left col-auto p-0 d-lg-none">
      <div class="logo-wrapper">
        <a><img src="assets/images/logo/isteghfar-72px.png" alt="" /></a>
      </div>
    </div>
    <div class="mobile-sidebar col-auto p-0">
      <div class="media-body text-start switch-sm">
        <label class="switch">
          <a (click)="collapseSidebar()">
            <app-feather-icons id="sidebar-toggle" [icon]="'align-left'"></app-feather-icons>
          </a>
        </label>
      </div>
    </div>
    <div class="nav-right col">
      <ul class="nav-menus" [ngClass]="{ open: openNav }">
        <li>
          <form class="form-inline search-form">
            <div class="form-group mb-0">
              <input class="form-control-plaintext" [class.open]="isOpenMobile" [(ngModel)]="text"
                [ngModelOptions]="{ standalone: true }" (keyup)="searchTerm(text)" type="text" autocomplete="off"
                placeholder="Search.." />
              <span class="d-sm-none mobile-search">
                <app-feather-icons [icon]="'search'" (click)="isOpenMobile = !isOpenMobile"></app-feather-icons>
              </span>
              <div class="Typeahead-menu" [class.is-open]="searchResult" *ngIf="menuItems?.length" id="search-outer">
                <div class="ProfileCard u-cf" *ngFor="let menuItem of menuItems | slice : 0 : 8">
                  <div class="ProfileCard-avatar">
                    <app-feather-icons [icon]="menuItem.icon" class="header-search"></app-feather-icons>
                  </div>
                  <div class="ProfileCard-details">
                    <div class="ProfileCard-realName">
                      <a [routerLink]="menuItem?.path" class="realname" (click)="removeFix()">{{ menuItem?.title }}</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Typeahead-menu" [class.is-open]="searchResultEmpty">
                <div class="tt-dataset tt-dataset-0">
                  <div class="EmptyMessage">Opps!! There are no result found.</div>
                </div>
              </div>
            </div>
          </form>
        </li>
        <li>
          <a class="text-dark" (click)="toggleFullScreen()">
            <app-feather-icons [icon]="'maximize'"></app-feather-icons>
          </a>
        </li>
        <li class="onhover-dropdown">
          <a class="txt-dark">
            <h6>
              <i class="mr-10 flag-icon" [ngClass]="currentLanguage ? currentLanguage.flag : ''"></i>
              {{currentLanguage?.label}}
            </h6>
          </a>
          <ul class="language-dropdown onhover-show-div p-20">
            <li *ngFor="let lang of languageList">
              <a (click)="changeLanguage(lang)">
                <i class="flag-icon" [ngClass]="lang.flag"></i>
                {{lang.label}}
              </a>
            </li>
          </ul>
        </li>
        <li class="onhover-dropdown">
          <div class="media align-items-center">
            <img class="align-self-center pull-right img-50 rounded-circle"
              [src]="user?.photoUrl || 'assets/images/loading.gif'" alt="user" />
            <div class="dotted-animation">
              <span class="animate-circle"></span>
              <span class="main-circle"></span>
            </div>
          </div>
          <ul class="profile-dropdown onhover-show-div p-20">
            <li>
              <a> <app-feather-icons [icon]="'user'"></app-feather-icons>Edit Profile </a>
            </li>
            <li>
              <a (click)="signOut()">
                <app-feather-icons [icon]="'log-out'"></app-feather-icons>
                {{"Logout" | translate}}
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
        <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
      </div>
    </div>
  </div>
</div>
<!-- Page Header Ends -->