export enum Language {
  Arabic = 'ar',
  English = 'en'
}

export enum LanguageLabel {
  Arabic = 'Arabic',
  English = 'English'
}

export const LanguageList: LanguageListType[] = [
  {
    name: Language.English,
    label: LanguageLabel.English,
    flag: 'flag-icon-is',
    layout: 'ltr'
  },
  {
    name: Language.Arabic,
    label: LanguageLabel.Arabic,
    flag: 'flag-icon-ar',
    layout: 'rtl'
  }
]

export const I18nLanguages: Language[] = [
  Language.English,
  Language.Arabic,
]

export class LanguageListType {
  name: Language;
  label: LanguageLabel;
  flag: string;
  layout: 'ltr' | 'rtl'
}