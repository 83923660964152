import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { LoaderComponent } from "./components/loader/loader.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { ContentLayoutComponent } from "./components/layout/content-layout/content-layout.component";
import { FullLayoutComponent } from "./components/layout/full-layout/full-layout.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { BookmarkComponent } from "./components/bookmark/bookmark.component";
import { TranslateModule } from "@ngx-translate/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// services
import { NavService } from "./services/nav.service";
import { CustomizerService } from "./services/customizer.service";
import { CustomizerComponent } from "./components/customizer/customizer.component";
import { NgbdSortableHeader } from "./directives/NgbdSortableHeader";
import { NgbdSortableHeader2 } from "./directives/sor-table.directive";
import { GetDatePipe } from './pipes/get-date.pipe';

const pipes = [
  GetDatePipe,
]

@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    BookmarkComponent,
    ContentLayoutComponent,
    FullLayoutComponent,
    FeatherIconsComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    NgbdSortableHeader,
    NgbdSortableHeader2,
    ...pipes,
  ],
  imports: [CommonModule, RouterModule, FormsModule, TranslateModule, NgbModule],
  exports: [LoaderComponent, FeatherIconsComponent, CustomizerComponent, TranslateModule,
    NgbdSortableHeader,
    NgbdSortableHeader2,
    ...pipes
  ],
  providers: [NavService, CustomizerService],
})
export class SharedModule { }
