import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';
import * as feather from 'feather-icons';
import { NavService } from 'src/app/shared/services/nav.service';
import { CustomizerService } from 'src/app/shared/services/customizer.service';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  animations: [
    trigger('animateRoute', [transition('* => *', useAnimation(fadeIn, {
      // Set the duration to 5seconds and delay to 2 seconds
      //params: { timing: 3}
    }))])
  ]
})
export class ContentLayoutComponent implements OnInit, AfterViewInit {


  public right_side_bar: boolean;

  constructor(
    public navServices: NavService,
    public customizer: CustomizerService,
  ) { }


  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  @HostListener('document:click', ['$event'])
  clickedOutside(event: any) {
    // click outside Area perform following action
    document.getElementById('outer-container')!.onclick = function (e) {
      e.stopPropagation()
      if (e?.target != document.getElementById('search-outer')) {
        const body = document.getElementsByTagName("body");
        if (body && body[0]) {
          body[0]?.classList?.remove("offcanvas");
        }
      }
      if (e?.target != document.getElementById('outer-container')) {
        document.getElementById("canvas-bookmark")?.classList.remove("offcanvas-bookmark");
      }
      if (e?.target != document.getElementById('inner-customizer')) {
        const customizerLinks = document.getElementsByClassName("customizer-links");
        if (customizerLinks && customizerLinks[0]) {
          customizerLinks[0]?.classList?.remove("open");
        }

        const customizerContain = document.getElementsByClassName("customizer-contain");
        if (customizerContain && customizerContain[0]) {
          customizerContain[0]?.classList?.remove("open")
        }
      }
    }
  }

  public getRouterOutletState(outlet: any) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  public rightSidebar($event: any) {
    this.right_side_bar = $event
  }

  ngOnInit() { }

}
